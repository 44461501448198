nav.navbar {
    border-radius: 0;
}

div.custom-pager {
    text-align: right;
    /*margin: 0 25px 0 0;*/
}

th.th-actions {
    min-width: 90px;
}

.panel .without-top-border {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dashboard {
    padding: 20px;
}

.dashboard .panel-body a {
    padding-left: 8px;
}

.borderless td, .borderless th {
    border: none !important;
}

.btn-invite {
    margin: 0px 10px;
}

.btn-invite-back {
    cursor: pointer;
}

.th-actions-floor {
    min-width: 210px;
}

.lb-sm {
    font-size: 12px;
}

.lb-md {
    font-size: 16px;
}

.lb-lg {
    font-size: 20px;
}

.table-text {
    max-width: 0px;
    overflow-wrap: break-word;
}

.table-label {
    float: left;
    margin: 4px;
    font-size: 90%;
    white-space: normal;
    word-break: break-all;
}

.break-word {
    word-break: break-all;
    white-space: normal;
}

.fix-max-width-tr {
    max-width: 0px;
}

li.menu-item>a:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
thead th {
    white-space: nowrap;
}
.sort-field {
    float: left;
}
.sort-direction {
    float: right;
}
.sort-no-float .sort-field,
.sort-no-float .sort-direction {
    float: none;
}
.padding-horizontal-5 {
    padding-right: 5px;
    padding-left: 5px;
}
.margin-left-10 {
    margin-left: 10px;
}